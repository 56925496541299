import request from '@/utils/request'

// 查询订单列表
export function listGroupTravelOrder(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/list',
    method: 'get',
    params: data
  })
}
// 验证订单信息，暂存订单
export function verificationGroupTravelOrder(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/verification-order',
    method: 'post',
    data: data
  })
}
// 报存订单信息
export function saveGroupTravelOrder(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/save',
    method: 'post',
    data: data
  })
}
// 确认订单信息支付情况
export function confirmGroupOrderPay(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/confirm-pay',
    method: 'post',
    data: data
  })
}
// 保存订单支付信息
export function groupOrderAddPayInfo(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/group-order-add-pay-info',
    method: 'post',
    data: data
  })
}


// 删除申报订单
export function delorder(groupOrderId) {
  return request({
    url: '/travelGroupOrder/group-travel-order/' + groupOrderId,
    method: 'delete'
  })
}

// 查询团队编号
export function generateTeavelCode(query) {
  return request({
    url: '/travelGroupOrder/group-travel-order/generate-group-travel-code',
    method: 'get',
    params: query
  })
}
// 查询团队编号
export function getGroupTravelOrderById(groupTravelOrderId) {
  return request({
    url: '/travelGroupOrder/group-travel-order/'+groupTravelOrderId,
    method: 'get',
  })
}
// 接单团队编号
export function recepOrders(groupTravelOrderId) {
  return request({
    url: '/travelGroupOrder/group-travel-order/recep/'+groupTravelOrderId,
    method: 'post',
  })
}
// 拒单团队编号
export function refuseOrders(groupTravelOrderId) {
  return request({
    url: '/travelGroupOrder/group-travel-order/refuse/'+groupTravelOrderId,
    method: 'post',
  })
}
// 退单团队编号
export function refundOrders(groupTravelOrderId) {
  return request({
    url: '/travelGroupOrder/group-travel-order/refund/'+groupTravelOrderId,
    method: 'post',
  })
}
// 审核订单团队编号
export function auditOrders(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/audit',
    method: 'post',
    data: data
  })
}

// 退订订单门票信息
export function refundOrderTicket(data) {
  return request({
    url: '/travelGroupOrder/group-travel-order/refund-order-ticket',
    method: 'post',
    data: data
  })
}

// 组团订单首页顶部统计
export function getGroupOrderCount(startDate,endDate) {
  return request({
    url: '/travelGroupOrder/group-travel-order-count/getGroupOrderCount?startDate='+startDate+'&endDate='+endDate,
    method: 'get',
  })
}

// 销售分析
export function saleAnalyse(query) {
  return request({
    url: '/travelGroupOrder/group-travel-order-count/saleAnalyse?dateType='+query.dateType+"&startDate="+query.startDate+"&endDate="+query.endDate,
    method: 'get'
  })
}

// 客源地分析
export function areaCount() {
  return request({
    url: '/travelGroupOrder/group-travel-order-count/areaCount',
    method: 'get'
  })
}
