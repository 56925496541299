import request from '@/utils/request'

// 查询区域信息列表
export function areaListByPid(pid) {
  return request({
    url: '/merchant/mer-area/area-list-pid',
    method: 'get',
    params: {pid: pid}
  })
}

// 查询省市级区域列表
export function provinceCityList() {
  return request({
    url: '/merchant/mer-area/province-city',
    method: 'get'
  })
}

// 根据区域编码获取区域信息
export function areaNameById(id) {
  return request({
    url: '/merchant/mer-area/area-id',
    method: 'get',
    params: {id: id}
  })
}
